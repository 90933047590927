<template>
  <a-spin :spinning="spinning">
    <div :class="{ disabled: disabled }">
      <a-form-model layout="inline" :model="form" :rules="rules" ref="form" style="margin-top:3px">
        <a-card class="card" title="业务信息" :bordered="false" style="margin-top:3px;line-height: 1;">
          <div slot="extra">
            <a-affix
              :offsetTop="50"
              :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
            >
              <a-button><router-link :to="{ name: 'SeaOrder' }">取消</router-link></a-button>
              <a-divider type="vertical" />
              <a-button :disabled="!isEdit" type="primary" @click="handleExport">导出委托书</a-button>
              <a-divider type="vertical" />
              <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmitNew" v-preventReClick>保存</a-button>
            </a-affix>
          </div>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="订单编号">
                <a-input v-model="form['serial_num']" disabled placeholder="保存后自动生成" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="报价编号" prop="quota_num">
                <a-input v-model="form['quota_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择委托单位"
                  style="width: 300px"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="订单类型">
                <a-select
                  placeholder="请选择订单类型"
                  style="width: 90px"
                  v-model="form['order_kind']"
                >
                  <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="拖运类型">
                <a-radio-group v-model="form['trans_type']">
                  <a-radio :value="1">整箱</a-radio>
                  <a-radio :value="2">拼箱</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="业务类型" :selfUpdate="false" prop="business_type">
                <a-radio-group v-model="form['business_type']" @change="radioChange">
                  <a-radio :value="1">海运进口</a-radio>
                  <a-radio :value="2">海运出口</a-radio>
                  <a-radio :value="3">空运进口</a-radio>
                  <a-radio :value="4">空运出口</a-radio>
                  <a-radio :value="0">保税仓储</a-radio>
                  <a-radio :value="11">进口内装</a-radio>
                  <a-radio :value="12">出口内装</a-radio>
                  <a-radio :value="13">其他</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-model-item label="服务类型" :selfUpdate="false" prop="service_types">
                <a-checkbox-group :options="serverTypeOps" v-model="form['service_types']">
                  <span slot="label" slot-scope="option">{{ option.name }}</span>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="进/出口报关单号">
                <a-input v-model="form['declare_num']" style="width: 130px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="订舱备注">
                <a-input v-model="form['order_remark']"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="配舱备注">
                <a-input v-model="form['match_remark']"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a @click="toggle('business')">
                {{ businessExpand ? '显示更多' : '收起' }}<a-icon :type="businessExpand ? 'down' : 'up'" />
              </a>
            </a-col>
          </a-row>
          <div :style="{ display: businessExpand ? 'none' : 'block' }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="客户业务编号">
                <a-input v-model="form['customer_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="贸易条款">
                <a-auto-complete
                  v-model="form['trade_term']"
                  style="width: 100%"
                  placeholder="请选择贸易条款"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in tradeTermOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="商检单号">
                <a-input v-model="form['check_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="清关类型">
                <a-input v-model="form['clear_type']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="核注清单号">
                <a-input v-model="form['clear_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="进境备案清单号">
                <a-input v-model="form['clear_record_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="订舱代理">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择订舱代理"
                  style="width: 183px"
                  ref="agent"
                  v-model="form['agent']"
                >
                  <a-select-option v-for="op in agentOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="船公司">
                <a-auto-complete
                  v-model="form['trans_company']"
                  style="width: 100%"
                  placeholder="请选择船公司"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in transCompanyOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="合约号">
                <a-input v-model="form['deal_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="form['business_type'] < 3 || form['business_type'] == 11 || form['business_type'] == 12 || form['business_type'] == 13">
              <a-form-model-item label="国外代理">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择国外代理"
                  style="width: 183px"
                  ref="agent_aboard"
                  v-model="form['agent_aboard']"
                >
                  <a-select-option v-for="op in agentAboardOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6"
              v-if="form['business_type'] == 1 || form['business_type'] == 3 || form['business_type'] == 11"
            >
              <a-form-model-item label="3C证书">
                <a-input v-model="form['ccc_cert']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6"
              v-if="form['business_type'] == 1 || form['business_type'] == 3 || form['business_type'] == 11"
            >
              <a-form-model-item label="能效证书">
                <a-input v-model="form['energy_cert']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6" v-if="form['service_types'].indexOf(6) > -1">
              <a-form-model-item label="货值">
                <a-input v-model="form['value']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="form['service_types'].indexOf(6) > -1">
              <a-form-model-item label="保费">
                <a-input v-model="form['premium']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="型号">
                <item-group
                  className="store-type-item"
                  :values.sync="store_type"
                  :fields="[{ type: 'input', name: 'storeType', style: 'width: calc(100% - 100px)' }]"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="区内仓库名称">
                <a-auto-complete
                  v-model="form['store_in_name']"
                  style="width: 100%"
                  placeholder="请选择区内仓库名称"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in storeInOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="区外仓库名称">
                <a-auto-complete
                  v-model="form['store_out_name']"
                  style="width: 100%"
                  placeholder="请选择区外仓库名称"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in storeOutOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="入境货物检验检疫证书">
                <a-input v-model="form['inspect_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="报关行">
                <a-auto-complete
                  v-model="form['custom_com']"
                  style="width: 100%"
                  placeholder="请选择报关行"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in customOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="车队">
                <a-auto-complete
                  v-model="form['trans_com']"
                  style="width: 100%"
                  placeholder="请选择车队"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in transComOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
          </a-row>
          </div>
        </a-card>
        <a-card class="card pc" title="配舱信息" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-drawer
            title="加拼管理"
            placement="left"
            :visible="visible"
            width="50%"
            :getContainer="false"
            @close="visible = false"
            :wrapStyle="{ position: 'absolute' }"
          >
            <edit-table :columns="orderColumns" :sourceData="orderData" @getTableDate="getOrderData"></edit-table>
          </a-drawer>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="提单号">
                <a-input v-model="form['delivery_num']"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" icon="plus-circle" style="width: 30px;margin-top: 2px" @click="handleAddOrder">加拼</a-button>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="船名">
                <a-input v-model="form['ship_name']" style="width:120px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="航次">
                <a-input v-model="form['ship_num']" style="width:120px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="起运港">
                <a-auto-complete
                  v-model="form['departure']"
                  style="width:120px"
                  placeholder="请选择起运港"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in setOffOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="目的港">
                <a-auto-complete
                  v-model="form['destination']"
                  style="width:120px"
                  placeholder="请选择目的港"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in destOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" v-if="form['business_type'] < 3 || form['business_type'] == 11 || form['business_type'] == 12 || form['business_type'] == 13">
            <a-col :span="18">
              <a-form-model-item label="箱型箱号箱量">
                <box-item-group :field="box_info"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a @click="toggle('allocation')">
                {{ allocationExpand ? '显示更多' : '收起' }}<a-icon :type="allocationExpand ? 'down' : 'up'" />
              </a>
            </a-col>
          </a-row>
          <div :style="{ display: allocationExpand ? 'none' : 'block' }">
            <a-row :gutter="24">
              <a-col :span="6">
                <a-form-model-item label="中转港代码">
                  <a-input v-model="form['transfer']" style="width: 100px"/>
                </a-form-model-item>
              </a-col>
              <a-col
                :span="6"
                v-if="form['business_type'] == 2 || form['business_type'] == 4 || form['business_type'] == 12 || form['business_type'] == 13"
              >
                <a-form-model-item label="ETD">
                  <a-date-picker v-model="form['etd']" class="full-width" />
                </a-form-model-item>
              </a-col>
              <a-col
                :span="6"
                v-if="
                  form['business_type'] == 1 ||
                    form['business_type'] == 3 ||
                    form['business_type'] == 0 || form['business_type'] == 11
                "
              >
                <a-form-model-item :label="form['business_type'] == 0 ? '出库时间' : 'ETA'">
                  <a-date-picker v-model="form['etd']" class="full-width" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" v-if="form['business_type'] !== 4">
                <a-form-model-item :label="form['business_type'] == 1 || form['business_type'] == 11 ? '申报时间' : '报关时间'">
                  <a-date-picker showTime v-model="form['ecd']" class="full-width" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" v-if="form['business_type'] == 4">
                <a-form-model-item label="换单时间">
                  <a-date-picker showTime v-model="form['ecd']" class="full-width" />
                </a-form-model-item>
              </a-col>
              <a-col
                :span="6"
                v-if="
                  form['business_type'] == 1 ||
                    form['business_type'] == 3 ||
                    form['business_type'] == 0 ||
                    form['business_type'] == 11
                "
              >
                <a-form-model-item label="放行时间">
                  <a-date-picker showTime v-model="form['end']" class="full-width" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="主单号">
                  <a-input v-model="form['order_num']" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" v-if="form['business_type'] === 3">
                <a-form-model-item label="分单号">
                  <a-input v-model="form['split_num']" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :span="6" v-if="form['business_type'] < 3 || form['business_type'] == 11 || form['business_type'] == 12 || form['business_type'] == 13">
              <a-col v-bind="colSpan">
                <a-form-model-item label="码头">
                  <a-auto-complete
                    v-model="form['port']"
                    style="width: 100%"
                    placeholder="请选择码头"
                    :filterOption="handleAutoSearch"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in portOps" :key="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-col :span="6" v-if="form['business_type'] > 2">
              <a-form-model-item label="航班号">
                <item-group
                  :values.sync="trans_num"
                  :fields="[{ type: 'input', name: 'transNum', style: 'width: 200px' }]"
                />
              </a-form-model-item>
            </a-col>
          </div>
        </a-card>
        <a-card class="card" title="提单信息" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24" type="flex" justify="start">
            <a-col :span="6" v-if="form['business_type'] === 0">
              <a-form-model-item label="总件数">
                <a-input-number style="width: 100%" v-model="form['total_num']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="form['business_type'] !== 0">
              <a-form-model-item label="总件数">
                <a-input-group compact>
                  <a-form-model-item style="width: 100px">
                    <a-input-number style="width: 100%" v-model="form['total_num']" />
                  </a-form-model-item>
                  <a-form-model-item style="width: 100px">
                    <a-auto-complete
                      v-model="form['unit']"
                      style="width: 100%"
                      placeholder="请选择包装方式"
                      :filterOption="handleAutoSearch"
                    >
                      <template slot="dataSource">
                        <a-select-option v-for="op in packageTypeOps" :key="op.name">{{ op.name }}</a-select-option>
                      </template>
                    </a-auto-complete>
                  </a-form-model-item>
                </a-input-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="总体积">
                <a-input suffix="m³" v-model="form['volume']" style="width: 100px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="总毛重">
                <a-input suffix="KGS" v-model="form['weight']" style="width: 100px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="总天数">
                <a-input v-model="form['total_day']" style="width: 100px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a @click="toggle('billInfo')">
                {{ billInfoExpand ? '显示更多' : '收起' }}<a-icon :type="billInfoExpand ? 'down' : 'up'" />
              </a>
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            v-if="
              form['business_type'] == 2 ||
                form['business_type'] == 4 ||
                form['business_type'] == 0 ||
                form['business_type'] == 12 ||
                form['business_type'] == 13
            "
          >
            <a-col :span="6">
              <a-form-model-item label="提货时间">
                <a-date-picker showTime placeholder="提货时间" v-model="form['delivery_date']" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="提货地点">
                <a-input v-model="form['delivery_address']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <div :style="{ display: billInfoExpand ? 'none' : 'block' }">
            <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="发货人">
                <a-input v-model="form['sender']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="收货人">
                <a-input v-model="form['receiver']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="通知人">
                <a-input v-model="form['reminders']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            v-if="form['business_type'] == 1 || form['business_type'] == 3 || form['business_type'] == 11"
          >
            <a-col :span="24">
              <a-form-model-item label="送货时间、送货地点、联系人、联系电话">
                <item-group
                  className="send-item"
                  :values.sync="send_info"
                  :fields="[
                    { type: 'date', name: 'send_date', style: 'width: 150px' },
                    {
                      type: 'input',
                      name: 'send_address',
                      style: 'width: calc(100% - 500px)',
                      placeholder: '送货地点'
                    },
                    { type: 'input', name: 'c', style: 'width: 100px', placeholder: '联系人' },
                    { type: 'input', name: 'n', style: 'width: 120px', placeholder: '联系电话' }
                  ]"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="国外代理">
                <a-input v-model="form['agent_bu_aboard']" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="唛头">
                <a-input v-model="form['mai']" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12" v-if="form['business_type'] !== 0">
              <a-form-model-item label="英文品名">
                <item-group
                  className="store-type-item"
                  :values.sync="eng_name"
                  :fields="[{ type: 'input', name: 'engName', style: 'width: calc(100% - 100px)' }]"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" v-if="form['business_type'] === 0">
            <a-col :span="24">
              <edit-table :columns="bsColumns" :sourceData="bsData" @getTableDate="getBsData"></edit-table>
            </a-col>
          </a-row>
          
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="付款方式">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择付款方式"
                  style="width: 183px"
                  v-model="form['pay_type']"
                >
                  <a-select-option v-for="op in payTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="运输条款">
                <a-auto-complete
                  v-model="form['trans_term']"
                  style="width: 100%"
                  placeholder="请选择运输条款"
                  :filterOption="handleAutoSearch"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="op in transTermOps" :key="op.name">{{ op.name }}</a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="提单形式">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择提单形式"
                  style="width: 183px"
                  v-model="form['lading_bill_type']"
                >
                  <a-select-option v-for="op in ladingBillOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          </div>
        </a-card>
        <!-- <a-row :gutter="20" style="margin-top:3px;line-height: 1;">
          <a-col :span="12">
            <a-card class="card" title="订舱备注" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
              <a-form-model-item label="">
                <a-textarea v-model="form['order_remark']" style="width:600px"/>
              </a-form-model-item>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card class="card" title="配舱备注" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
              <a-form-model-item label="">
                <a-textarea v-model="form['match_remark']" style="width:600px"/>
              </a-form-model-item>
            </a-card>
          </a-col>
        </a-row> -->
        <a-card class="card" title="费用信息" :bordered="false" style="margin-top:3px;line-height: 1;">
          <a-tabs default-active-key="a">
            <a-tab-pane key="a" tab="应收费用">
              <edit-table
                :columns="chargeFeeColumns"
                :sourceData="chargeFeeData"
                :feeStatusFlag="true"
                @getTableDate="getChargeFeeData"
                @getTableDateError="getTableDateError"
              ></edit-table>
            </a-tab-pane>
            <a-tab-pane key="b" tab="应付费用">
              <edit-table
                ref="order_pay_fee"
                :columns="payFeeColumns"
                :sourceData="payFeeData"
                :feeStatusFlag="true"
                @getTableDate="getPayFeeData"
                @getTableDateError="getTableDateError"
              ></edit-table>
            </a-tab-pane>
            <template slot="tabBarExtraContent">
              <template>
                <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
                <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
                <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
                <!--<a-button type="primary" :disabled="!isEdit">
                  现金收费
                </a-button>-->
              </template>
            </template>
          </a-tabs>
        </a-card>
      </a-form-model>
    </div>
  </a-spin>
</template>
<script>
import { getCommonOptions, getCustomer, getSupplier, downloadExcel } from '@/api/common'
import { getFirstLetter, customizeAssign } from '@/utils/util'
import {
  getItmsMetaOption,
  getItmsOrderDetail,
  getItmsOrderCopyInfo,
  createItmsOrderInfo,
  exportItmsOrderData,
  getItmsOrderQuotaNum
} from '@/api/itms'
import { EditTable, BoxItemGroup, ItemGroup } from '@/components'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce'
export default {
  components: {
    EditTable,
    BoxItemGroup,
    ItemGroup,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      businessExpand: true,
      allocationExpand: true,
      billInfoExpand: true,
      confirmLoading: false,
      spinning: false,
      isEdit: false,
      customerInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      visible: false,
      disabled: false,
      box_info: [{ id: null, num: null, box_num: '' }],
      store_type: [], // 型号
      trans_num: [], // 航次和航班号公用
      send_info: [],
      eng_name: [],
      sourceForm: {
        id: '',
        serial_num: '',
        quota_num: '',
        auth_agency: null,
        customer_num: '',
        trade_term: null,
        trans_type: 1,
        order_kind: 1,
        service_types: [],
        business_type: null,
        declare_num: '', // 进/出口报关单号
        store_in_name: '', // 仓库名称
        store_out_name: '',
        custom_com: '',
        trans_com: '',
        inspect_num: '',
        agent: null,
        trans_company: '',
        deal_num: '',
        agent_aboard: null,
        value: null,
        premium: null,
        check_num: '',
        clear_type: '',
        clear_num: '',
        clear_record_num: '',
        // 配舱信息
        departure: '',
        destination: '',
        transfer: '',
        etd: null,
        end: null,
        ecd: null,
        order_num: '',
        delivery_num: '',
        ship_name: '',
        ship_num: '',
        port: '',
        sender: '',
        receiver: '',
        reminders: '',
        agent_bu_aboard: '',
        mai: '',
        total_num: null,
        unit: null,
        weight: null,
        total_day: null,
        volume: null,
        pay_type: null,
        trans_term: '',
        lading_bill_type: null,
        order_remark: '',
        match_remark: '',
        ccc_cert: '',
        energy_cert: '',
        delivery_date: null,
        delivery_address: '',
        split_num: ''
      },
      form: {},
      orderColumns: [
        {
          title: '加拼关单号',
          dataIndex: 'custom_num',
          width: 120
        },
        {
          title: '加拼分单号',
          dataIndex: 'append_num',
          width: 120
        }
      ],
      orderData: [],
      agencyOps: [],
      orderTypeOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 }
      ],
      agentOps: [], // 订舱代理
      agentAboardOps: [],
      customOps: [],
      transComOps: [],
      tradeTermOps: [],
      payTypeOps: [
        { value: 1, name: 'FREIGHT_PREPAID' },
        { value: 2, name: 'FREIGHT_COLLECT' }
      ],
      transTermOps: [],
      ladingBillOps: [
        { value: 1, name: '正本' },
        { value: 2, name: '电放' },
        { value: 3, name: 'SEAWAY' }
      ],
      transCompanyOps: [],
      setOffOps: [],
      destOps: [],
      shipOps: [],
      portOps: [],
      storeInOps: [],
      storeOutOps: [],
      packageTypeOps: [],
      serverTypeOps: [
        { value: 1, name: '订舱' },
        { value: 2, name: '拖车' },
        { value: 3, name: '内装' },
        { value: 4, name: '报关' },
        { value: 5, name: '海外段' },
        { value: 6, name: '保险' },
        { value: 7, name: '租箱' },
        { value: 8, name: '熏蒸' },
        { value: 9, name: '买单' },
        { value: 10, name: '办证' },
        { value: 11, name: '制单' },
        { value: 12, name: '区内仓储' },
        { value: 13, name: '区外仓储' },
        { value: 14, name: '换单' },
        { value: 15, name: '贸易代理' },
        { value: 16, name: '接单' },
        { value: 17, name: '进口' },
        { value: 18, name: '出口' },
        { value: 19, name: '仓储服务' }
      ],
      payTargets: [],
      bsData: [], // 保税信息
      bsColumns: [
        {
          title: '品名',
          dataIndex: 'name',
          width: 100
        },
        {
          title: '件数',
          dataIndex: 'num',
          extra: {
            type: 'number',
            extraFunc: this.calcTotalInfo
          },
          width: 100
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: 100
        },
        {
          title: '体积',
          dataIndex: 'volume',
          extra: {
            extraFunc: this.calcTotalInfo
          },
          width: 100
        },
        {
          title: '堆存天数',
          dataIndex: 'store_days',
          extra: {
            type: 'number'
          },
          width: 100
        }
      ],
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          width: 60,
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['fee', { department: 2, type: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [{ department: 2 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0,
      // 表单校验
      rules: {
        // 报价编号
        quota_num: [{ required: true, message: '请输入报价编号', trigger: 'blur' }],
        // 业务类型
        business_type: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }]
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'SeaOrderCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        this.$route.meta.title = '新增订单'
        if (newRoute.params.id === 'create' || newRoute.query.isCopy) {
          // 新增或复制
          if (newRoute.query.isCopy) {
            this.getDetailData(newRoute.params.id, true)
          } else {
            this.isEdit = true
          }
        } else {
          this.disabled = newRoute.query.disabled
          this.getDetailData(newRoute.params.id)
        }
      }
    }
  },
  created() {
    this.handleFormReset()
    this.getBasicData()
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled !== 'false' && this.$route.query.disabled
      this.getDetailData(this.$route.params.id, this.$route.query.isCopy)
    }
  },
  methods: {
    toggle(type) {
      if (type === 'business') {
        this.businessExpand = !this.businessExpand
      } else if (type === 'allocation') {
        this.allocationExpand = !this.allocationExpand
      } else if (type === 'billInfo') {
        this.billInfoExpand = !this.billInfoExpand
      }
      
    },
    radioChange() {
      const type = this.form['business_type'];
      if (type === 3 || type === 4) {
        this.form['agent_aboard'] = null;
      }
    },
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
      // for (const key in this.sourceForm) {
      //   this.$set(this.form, key, this.sourceForm[key])
      // }
      this.customerInfo = undefined
      this.disabled = false
      this.store_type = [{ storeType: '' }]
      this.trans_num = []
      this.send_info = [{ send_date: null, send_address: '', c: '', n: '' }]
      this.eng_name = [{ engName: '' }]
      this.box_info = [{ id: null, num: null, box_num: '' }]
      this.orderData = []
      this.chargeFeeData = []
      this.payFeeData = []
      this.totalChargeFee = 0
      this.totalPayFee = 0
      this.form = {
        ...this.sourceForm
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      getCustomer({
        department: 2,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.form['agency_name'] = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getDetailData(id, isCopy) {
      this.spinning = true
      this.isEdit = !isCopy
      this.$route.meta.title = isCopy ? '新增订单' : '编辑订单'
      let func = getItmsOrderDetail
      if (isCopy) {
        func = getItmsOrderCopyInfo
      }
      func({ id: id })
        .then(v => {
          const form = { ...this.sourceForm }
          if (this.form['business_type'] < 3) {
            form.ship_num = (v.content.trans_num && v.content.trans_num[0]) || ''
          }
          customizeAssign(form, {
            ...v.order,
            ...v.content,
            id: isCopy ? null : v.order.id,
            etd: v.content['etd'] ? moment(v.content['etd'], 'YYYY/MM/DD') : null,
            end: v.content['end'] ? moment(v.content['end'], 'YYYY/MM/DD') : null,
            ecd: v.content['ecd'] ? moment(v.content['ecd'], 'YYYY/MM/DD') : null,
            delivery_date: v.content['delivery_date'] ? moment(v.content['delivery_date'], 'YYYY/MM/DD HH:mm:ss') : null
          })
          // form.quota_num = null
          this.customerInfo = {
            key: v.order.auth_agency,
            label: v.order.agency_name
          }
          this.box_info = v.order.box_info || this.box_info
          this.store_type = v.content.store_type.map(s => {
            return { storeType: s }
          })
          this.eng_name = v.content.eng_name.map(s => {
            return { engName: s }
          })
          this.trans_num = v.content.trans_num.map(s => {
            return { transNum: s }
          })
          this.send_info = v.content['send_info'].map(s => {
            return {
              send_date: s['send_date'] ? moment(s['send_date'], 'YYYY/MM/DD HH:mm:ss') : null,
              send_address: s['send_address'],
              c: s['c'],
              n: s['n']
            }
          })
          this.form = form
          this.bsData = v.content.store_info
          // 复制功能直接将费用状态全部改为初始值:待审核的
          if (isCopy) {
            v.charge_fees.forEach(fee => {
              fee.fee_status = 1
              fee.is_balance = 1
              fee.is_payment = 1
            })
            v.pay_fees.forEach(fee => {
              fee.fee_status = 1
              fee.is_balance = 1
              fee.is_payment = 1
            })
          }
          // 判断费用状态是否为商务待审核状态,如果是的话,就禁用掉删除按钮
          v.charge_fees.forEach(fee => {
            if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
          })
          v.pay_fees.forEach(fee => {
            if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
          })
          this.chargeFeeData = v.charge_fees
          this.chargeFeeData.forEach(v => {
            this.totalChargeFee += parseFloat(v.money)
          })
          this.payFeeData = v.pay_fees
          this.payFeeData.forEach(v => {
            this.totalPayFee += parseFloat(v.money)
          })
          const customlen = v.order.custom_num ? v.order.custom_num.length : 0
          const appendlen = v.content.append_num ? v.content.append_num.length : 0
          const len = customlen > appendlen ? customlen : appendlen
          for (let i = 0; i < len; i++) {
            this.orderData.push({
              custom_num: v.order.custom_num[i] || '',
              append_num: v.content.append_num[i] || ''
            })
          }
          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
    },
    getBasicData() {
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      getItmsMetaOption('itms_role', { role_type: 1 }).then(v => {
        this.agentOps = v
      })
      getItmsMetaOption('itms_role', { role_type: 2 }).then(v => {
        this.agentAboardOps = v
      })
      getItmsMetaOption('itms_role', { role_type: 3 }).then(v => {
        this.customOps = v
      })
      getCommonOptions('common_type', { db_type: 3 }).then(v => {
        this.packageTypeOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 4 }).then(v => {
        this.transCompanyOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 1 }).then(v => {
        this.setOffOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 2 }).then(v => {
        this.destOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 5 }).then(v => {
        this.shipOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 3 }).then(v => {
        this.portOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 9 }).then(v => {
        this.tradeTermOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 11 }).then(v => {
        this.transTermOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 12 }).then(v => {
        this.transComOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 13 }).then(v => {
        this.storeInOps = v
      })
      getItmsMetaOption('itms_trans_com', { type: 14 }).then(v => {
        this.storeOutOps = v
      })
    },
    handleAddOrder() {
      // 加拼
      this.visible = true
    },
    getOrderData(data) {
      this.orderData = data
    },
    getBsData(data) {
      this.bsData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    calcTotalInfo(value, newData, target) {
      let totalNum = 0
      let totalVolumn = 0
      newData.forEach(row => {
        totalNum += row.num || 0
        totalVolumn += parseFloat(row.volume) || 0
      })
      this.form.setFieldsValue({ total_num: totalNum, volume: totalVolumn })
      return newData
    },
    handleSubmitNew(){
      if (this.$route.meta.title === '新增订单') {
        getItmsOrderQuotaNum({ 'quota_num': this.form.quota_num }).then(res => {
          if (res) {
            this.$message.info('存在相同的报价编号~')
          } else {
            this.handleSubmit()
          }
        });
      }else{
        this.handleSubmit()
      }
    },
    handleSubmit() {
      if (this.$route.meta.title === '新增订单') {
        getItmsOrderQuotaNum({ 'quota_num': this.form.quota_num }).then(res => {
          if (res) {
            this.$message.info('存在相同的报价编号~')
          }
        });
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          const customNum = []
          const appendNum = []
          this.orderData.forEach(o => {
            customNum.push(o.custom_num)
            appendNum.push(o.append_num)
          })
          const orderKeys = [
            'id',
            'serial_num',
            'quota_num',
            'auth_agency',
            'agency_name',
            'customer_num',
            'trade_term',
            'trans_type',
            'order_kind',
            'service_types',
            'business_type',
            'agent',
            'agent_name',
            'trans_company',
            'deal_num',
            'agent_aboard',
            'agent_aboard_name',
            'value',
            'premium',
            'order_num',
            'delivery_num',
            'box_info',
            'clear_type',
            'clear_record_num',
            'declare_num',
            'split_num',
            'clear_num',
            'custom_num'
          ]
          const contentKeys = [
            'departure',
            'destination',
            'transfer',
            'etd',
            'end',
            'ecd',
            'ship_name',
            'trans_num',
            'port',
            'sender',
            'receiver',
            'reminders',
            'agent_bu_aboard',
            'mai',
            'eng_name',
            'total_num',
            'unit',
            'weight',
            'total_day',
            'volume',
            'store_info',
            'pay_type',
            'trans_term',
            'lading_bill_type',
            'order_remark',
            'match_remark',
            'ccc_cert',
            'energy_cert',
            'send_info',
            'delivery_date',
            'delivery_address',
            'store_days',
            'inspect_num',
            'store_type',
            'store_in_name',
            'store_out_name',
            'check_num',
            'append_num',
            'custom_com',
            'trans_com'
          ]
          const form = this.form
          const tempData = {
            ...form,
            custom_num: customNum,
            append_num: appendNum,
            box_info: this.box_info,
            store_type: this.store_type.map(v => {
              return v['storeType']
            }),
            eng_name: this.eng_name.map(v => {
              return v['engName']
            }),
            trans_num:
              form.business_type > 2
                ? this.trans_num.map(v => {
                    return v['transNum']
                  })
                : form.ship_num
                ? [form.ship_num]
                : [''],
            etd: form.etd ? moment(form.etd).valueOf() : null,
            end: form.end ? moment(form.end).valueOf() : null,
            ecd: form.ecd ? moment(form.ecd).valueOf() : null,
            store_info: this.bsData,
            send_info: this.send_info.map(s => {
              return {
                send_date: s.send_date ? moment(s.send_date).valueOf() : null,
                send_address: s.send_address,
                c: s.c,
                n: s.n
              }
            }),
            delivery_date: form.delivery_date ? moment(form.delivery_date).valueOf() : null,
            agent_name: form.agent ? this.$refs['agent'].$el.innerText : '',
            agent_aboard_name: form.agent_aboard && this.$refs['agent_aboard'] ? this.$refs['agent_aboard'].$el.innerText : ''
          }
          const orderData = {}
          const contentData = {}
          for (const key in tempData) {
            if (orderKeys.includes(key)) {
              orderData[key] = tempData[key] === undefined ? null : tempData[key]
            }
            if (contentKeys.includes(key)) {
              contentData[key] = tempData[key] === undefined ? null : tempData[key]
            }
          }
          const data = {
            order: orderData,
            content: contentData,
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          createItmsOrderInfo('save_main', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            // this.$router.push({
            //   name: 'SeaOrder',
            //   params: {
            //     order: orderData,
            //     content: contentData
            //   },
            //   query: {
            //     isEdit: this.isEdit
            //   }
            // });
          });
        } else {
          return false;
        }
      });
    },
    handleExport() {
      exportItmsOrderData({ id: this.form['id'] }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
.table-title {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 800;
  /* color: #1890ff */
}
.field-item {
  position: relative;
  width: 200px;
  margin-right: 10px;
  margin-top: 10px;
  color: red;
}
.field-item:last-child {
  width: 280px;
}
.field-item_del {
  position: absolute;
  top: -5px;
  left: 190px;
  color: #ccc;
  z-index: 999;
  cursor: pointer;
}
.field-item_del:hover,
.send-item_del:hover {
  color: #1890ff;
}
</style>
<style>
.pc .ant-drawer {
  display: none;
}
.pc .ant-drawer-open {
  display: block;
}
.field-item.store-type-item,
.field-item.store-type-item:last-child {
  width: 100%;
}
.field-item.store-type-item .field-item_del {
  left: calc(100% - 110px);
}
.field-item.send-item,
.field-item.send-item:last-child {
  width: 100%;
}
.field-item.send-item .field-item_del {
  left: calc(100% - 110px);
}
.ant-card-body {
    padding: 12px;
    zoom: 1;
}
.ant-table-placeholder {
    padding: 6px;
}
.ant-card-head {
    min-height: 36px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
}
.ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 1000
}
</style>
